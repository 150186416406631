import { uuid4 } from '@sentry/utils'

export const SECTIONS = {
    taskList: 'taskList',
    analytics: 'analytics',
    activations: 'activations',
    audience: 'audience',
    messages: 'messages',
    overview: 'overview',
}
export const SIDEBAR_SECTIONS = {
    createActivation: 'createActivations',
    editActivation: 'editActivations',
    createAudience: 'createAudience',
    editAudience: 'editAudience',
    createMessage: 'createMessage',
    editMessage: 'editMessage',
    clientInfo: 'clientInfo',
    batchMode: 'batchMode',
}
// export const TASKLIST_STATUSES = {
//     DONT_REMIND: 'Не напоминаем клиентам',
//     DONT_REMIND_SERVICE: 'Не напоминаем по услугам',
//     NEED_TO_CONTACT: 'Нужно связаться',
//     POSTPONED_COMMUNICATION: 'Отложенная коммуникация',
//     CONTACTED_AND_DONT_COME: 'Связались, но пока не пришли и не записались',
//     CONTACTED_AND_COME: 'Связались, а потом пришли или записались',
//     NEED_TO_CONTACT_TODAY: 'Нужно связаться сегодня',
//     NEED_TO_CONTACT_YESTERDAY: 'Нужно было связаться вчера или ранее',
//     IN_PROGRESS: 'В очереди на отправку',
//     COMMUNICATION_ERROR: 'Ошибки отправки',
// }

export const MANUAl_COMMUNICATION_DATE_FORMAT = 'YYYY-MM-DD'
export const VISIT_STATUSES = {
    ATTENDANCE: 'Посещение',
    CANCEL: 'Отмена',
    RECORD: 'Запись',
}

export const TASKLIST_COMMUNICATIONS_TYPES = {
    NO: 'no',
    MESSAGE: 'mess',
    PHONE: 'phone',
}

// export const TASKLIST_ACTION_TYPES = {
//     CONTACT: 'CONTACT',
//     DONT_CONTACT: 'DONT_CONTACT',
//     REMIND: 'REMIND',
//     DONT_REMIND: 'DONT_REMIND',
//     DONT_REMIND_SERVICE: 'DONT_REMIND_SERVICE',
//     CHANGE_EXPECTED_DATE: 'CHANGE_EXPECTED_DATE',
//     CHANGE_COMMENT: 'CHANGE_COMMENT',
//     CHANGE_MODA: 'CHANGE_MODA',
//     SEND_MESSAGE: 'SEND_MESSAGE',
//     CHANGE_TEXT_MESSAGE: 'CHANGE_TEXT_MESSAGE',
//     REMOVE_CLIENT: 'REMOVE_CLIENT',
//     MAKE_CALL: 'MAKE_CALL',
//     BATCH_CHANGE_TEXT_MESSAGE: 'BATCH_CHANGE_TEXT_MESSAGE',
//     BATCH_CONTACT: 'BATCH_CONTACT',
//     IN_PROGRESS: 'IN_PROGRESS',
//     BATCH_IN_PROGRESS: 'BATCH_IN_PROGRESS',
//     COMMUNICATION_ERROR: 'COMMUNICATION_ERROR',
//     REMOVE_MESS: 'REMOVE_MESS',
//     IN_PROGRESS_AGAIN: 'IN_PROGRESS_AGAIN',
//     CONTACT_AFTER_SENDING_MESSAGE: 'CONTACT_AFTER_SENDING_MESSAGE'
// }

export const TASKLIST_STATUSES = {
    WAIT_INTERVIEW: 'Ожидает собеседования',
    NEED_TO_CONTACT: 'Нужно связаться',
    NEED_TO_CONTACT_TODAY: 'Нужно связаться сегодня',
    NEED_TO_CONTACT_YESTERDAY: 'Нужно было связаться вчера или ранее',
    NEED_TO_CONTACT_LATER: 'Нужно связаться позже',
    TRANSFERRED_TO_CUSTOMER: 'Передан Заказчику',
    HIRED: 'Нанят',
    TRANSFERRED_TO_ANOTHER_VACANCY: 'Передан в другую вакансию',
    BANNED: 'Черный список'
};

export const TASKLIST_ACTION_TYPES = {
    CANCEL_AN_APPOINTMENT: 'Отменить встречу',
    MAKE_AN_APPOINTMENT: 'Назначить встречу',
    POSTPONE: 'Отложить',
    CONTACT_LATER: 'Связаться позже',
    RETURN_TO_CUSTOMER: 'Вернуть от Заказчика',
    TRANSFER_TO_CUSTOMER: 'Передать Заказчику',
    TRANSFER_CANDIDATE: 'Передать кандидата в другую вакансию',
    RETURN_CANDIDATE: 'Вернуть кандидата из другой вакансии',
    ADD_TO_BLACKLIST: 'Убрать в ЧС',
    REMOVE_FROM_BLACKLIST: 'Вернуть из ЧС',
    MAKE_CALL: 'Позвонить',
    WRITE: 'Написать',
};

const COMMON_WIP_ACTIONS = [
    TASKLIST_ACTION_TYPES.MAKE_AN_APPOINTMENT,
    TASKLIST_ACTION_TYPES.TRANSFER_CANDIDATE,
    TASKLIST_ACTION_TYPES.TRANSFER_TO_CUSTOMER,
    TASKLIST_ACTION_TYPES.ADD_TO_BLACKLIST,
];

// Доступные действия для каждого статуса с учетом направлений стрелок
export const TASKLIST_AVAILABLE_ACTIONS = {
    "Ожидает собеседования": [
        TASKLIST_ACTION_TYPES.CANCEL_AN_APPOINTMENT // Отменить встречу
    ],
    "Нужно связаться": [
        ...COMMON_WIP_ACTIONS,
        TASKLIST_ACTION_TYPES.POSTPONE,
        TASKLIST_ACTION_TYPES.MAKE_CALL,
        TASKLIST_ACTION_TYPES.WRITE
    ],
    "Отложено на сегодня": [
        ...COMMON_WIP_ACTIONS,
        TASKLIST_ACTION_TYPES.CONTACT_LATER          // Связаться позже
    ],
    "Отложено на будущее": [
        ...COMMON_WIP_ACTIONS,
        TASKLIST_ACTION_TYPES.CONTACT_LATER          // Связаться позже
    ],
    "Нужно связаться позже": [
        ...COMMON_WIP_ACTIONS,
        TASKLIST_ACTION_TYPES.CONTACT_LATER          // Связаться позже
    ],
    "Нужно связаться сегодня": [
        ...COMMON_WIP_ACTIONS,
        TASKLIST_ACTION_TYPES.POSTPONE               // Отложить
    ],
    "Нужно было связаться вчера или ранее": [
        ...COMMON_WIP_ACTIONS,
        TASKLIST_ACTION_TYPES.POSTPONE               // Отложить
    ],
    "Передан Заказчику": [
        TASKLIST_ACTION_TYPES.RETURN_TO_CUSTOMER     // Вернуть к Заказчику
    ],
    "Нанят": [
        // Никаких доступных действий, так как кандидат уже нанят
    ],
    "Передан в другую вакансию": [
        TASKLIST_ACTION_TYPES.RETURN_CANDIDATE       // Вернуть кандидата
    ],
    "Черный список": [
        TASKLIST_ACTION_TYPES.REMOVE_FROM_BLACKLIST  // Вернуть из ЧС
    ]
};

export const actionsToStatus = {
    "Отменить встречу":  TASKLIST_STATUSES.NEED_TO_CONTACT
};


export const COMMUNICATION_ERROR_CODES = {
    'Не валидный формат номера телефона': 'INVALID_NUMBER_FORMAT',
    'На телефоне клиента нет WhatsApp': 'WHATSAPP_NOT_FOUND',
    'WhatsApp бот не подключен': 'DEAD_AUTH',
}
export const TASKLIST_ACTION_COLORS = {
    [TASKLIST_ACTION_TYPES.CONTACT]: 'contact',
    [TASKLIST_ACTION_TYPES.CHANGE_EXPECTED_DATE]: 'changed',
    [TASKLIST_ACTION_TYPES.DONT_REMIND]: 'deleted',
    [TASKLIST_ACTION_TYPES.DONT_CONTACT]: 'changed',
    [TASKLIST_ACTION_TYPES.REMIND]: 'changed',
    [TASKLIST_ACTION_TYPES.CHANGE_COMMENT]: 'changed',
    [TASKLIST_ACTION_TYPES.CHANGE_TEXT_MESSAGE]: 'changed',
    [TASKLIST_ACTION_TYPES.BATCH_CHANGE_TEXT_MESSAGE]: 'changed',
    [TASKLIST_ACTION_TYPES.BATCH_CONTACT]: 'contact',
    [TASKLIST_ACTION_TYPES.REMOVE_CLIENT]: 'deleted',
    [TASKLIST_ACTION_TYPES.IN_PROGRESS]: 'contact',
    [TASKLIST_ACTION_TYPES.COMMUNICATION_ERROR]: 'deleted',
    [TASKLIST_ACTION_TYPES.CONTACT_AFTER_SENDING_MESSAGE]: 'contact',
}

export const DEFAULT_ACTIVATIONS_LIMIT = 3
export const ACTIVATION_LIMIT_TEXT_TURN_ON = 'Включить активацию'
export const ACTIVATION_LIMIT_TEXT_TURN_OFF = 'Выключить активацию'
export const ACTIVATION_LIMIT_TEXT_LIMIT_EXCEEDED = limit =>
    `В проекте может быть не больше ${limit} включенных активаций. Чтобы включить эту — выключите какую-то другую.`
export const DEFAULT_TASKLIST_BATCH_MODE_SELECT_LIMIT = 100
export const ACTIVATION_NAME_MAX_LENGTH = 150
export const ACTIVATION_DESCRIPTION_MAX_LENGTH = 1024
export const ACTIVATION_GROUP_MAX_LENGTH = 50
export const AUDIENCE_NAME_MAX_LENGTH = 150
export const AUDIENCE_GROUP_MAX_LENGTH = 50
export const MESSAGE_NAME_MAX_LENGTH = 150
export const MESSAGE_GROUP_MAX_LENGTH = 50
export const TASKLIST_CHANGE_COLOR_TIME = 3000 //ms
export const EMTY_AUDIENCE_FILTER = { id: 1, value: null }
export const EMTY_AUDIENCE_SETTINGS = { filters: [EMTY_AUDIENCE_FILTER] }
export const EMTY_ACTIVATION_SETTINGS = { messageId: null, audienceId: null, description: '' }
export const START_MESSAGE_PART = {
    _id: uuid4(),
    textVariant: '',
    partNumber: 1,
}
export const CHANGE_ACTIVATION_TYPES = { create: 'create', edit: 'edit' }
export const CHANGE_BOT_TRIGGER_TYPES = { create: 'create', edit: 'edit' }
export const CHANGE_KPI_SETTING_TYPES = { create: 'create', edit: 'edit' }
export const EDIT_MASTER_TYPE = { create: 'create', edit: 'edit' }
export const DEFAULT_MAX_ACTVATIONS_COUNT = 3
export const DEFAULT_KPI_SETTING = {
    name: '',
    description: '',
    is_default: false,
    scheme: {
        convertion_range_sa: [],
        staff_action_value: {
            communication: {
                0: {
                    default: {
                        fixed: 0,
                    },
                },
            },
            postponed: {
                0: {
                    default: {
                        fixed: 0,
                    },
                },
            },
            banned: {
                0: {
                    default: {
                        fixed: 0,
                    },
                },
            },
        },
        record_value: {
            default: {
                fixed: 0,
            },
        },
        revenue_range_visit: [],
        visit_value: {
            default: {
                0: {
                    default: {
                        fixed: 0,
                        percent: 0,
                    },
                },
            },
        },
    },
}

export const KPI_NAME_MAX_LENGTH = 150
export const KPI_DESCRIPTION_MAX_LENGTH = 1024

export const MESSAGE_VARS = [
    { id: '{name}' },
    { id: '{имя-пользователя}' },
    { id: '{дата-мероприятия}' },
    { id: '{cсылка-на-отзывы}' },
    { id: '{название-подарка}' },
    { id: '{ссылка-на-онлайн-запись}' },
    { id: '{название-салона}' },
    { id: '{адрес-салона}' },
    { id: '{контактный-телефон}' },
]

export const SCHEDULED_REPORT_NAME_MAX_LENGTH = 150

export const AUDIENCE_BOOLEAN_SELECT_DEFAULT_LIST = {
    trueText: 'Да',
    falseText: 'Нет',
}

export const AUDEINCE_ADDITIONAL_SETTINGS = {
    is_technical: {
        defaultValue: false,
        canEdit: true,
        schema: [
            {
                type: 'text',
                text:
                    'Если в новой активации связались с клиентом, то он должен уйти из других активаций, чтобы с ним повторно не связываться? —',
            },
            {
                type: 'select',
                label: '',
                multiple: false,
                customList: [
                    {
                        id: false,
                        name: 'Да',
                    },
                    {
                        id: true,
                        name: 'Нет',
                    },
                ],
                valueField: 'is_technical',
                defaultValue: false,
            },
        ],
    },
    no_comms_after_last_visit: {
        defaultValue: false,
        canEdit: true,
        schema: [
            {
                type: 'text',
                text:
                    'Должны ли в новую активацию попадать клиенты, с которыми после прошлого визита уже связывались по другим активациям? —',
            },
            {
                type: 'select',
                label: '',
                multiple: false,
                customList: [
                    {
                        id: false,
                        name: 'Да',
                    },
                    {
                        id: true,
                        name: 'Нет',
                    },
                ],
                valueField: 'no_comms_after_last_visit',
                defaultValue: false,
            },
        ],
    },
    last_communication_delay: {
        defaultValue: 30,
        canEdit: true,
        schema: [
            {
                type: 'text',
                text:
                    'Если в новую активацию, которую вы хотите, попали клиенты, с которыми вы связались уже ранее по другим активациям, но они так и не записались, через сколько дней после такой коммуникации они должны попадать в новую? —',
            },
            {
                type: 'numberInput',
                valueField: 'last_communication_delay',
                defaultValue: 30,
            },
        ],
    },
    last_visit_from: {
        defaultValue: 30,
        canEdit: true,
        schema: [
            {
                type: 'text',
                text: 'Сколько дней должно пройти с даты последнего визита на любую услугу? — \n',
            },
            {
                type: 'text',
                text: 'от',
            },
            {
                type: 'numberInput',
                valueField: 'last_visit_from',
                defaultValue: 30,
                width: 70,
            },
            {
                type: 'text',
                text: 'до',
            },
            {
                type: 'numberInput',
                valueField: 'last_visit_to',
                width: 70,
                defaultValue: 8000,
            },
        ],
    },
    last_visit_to: {
        defaultValue: 8000,
        canEdit: true,
        hidden: true,
    },
    no_future_records: {
        defaultValue: true,
        canEdit: true,
        schema: [
            {
                type: 'text',
                text: 'Если клиент уже записан на будущее, он должен попасть в эту активацию? —',
            },
            {
                type: 'select',
                label: '',
                multiple: false,
                customList: [
                    {
                        id: false,
                        name: 'Да',
                    },
                    {
                        id: true,
                        name: 'Нет',
                    },
                ],
                valueField: 'no_future_records',
                defaultValue: true,
            },
        ],
    },
    regularity: {
        defaultValue: 'one_time',
        canEdit: false,
        schema: [
            {
                type: 'text',
                text:
                    'После того, как с клиентом свяжутся по новой активации, должен ли он когда-нибудь попадать в неё снова? —',
            },
            {
                type: 'select',
                label: '',
                multiple: false,
                showTooltip: true,
                width: 458,
                customList: [
                    {
                        id: 'one_time',
                        name:
                            'Если клиент один раз попал в список, и с ним связались в этой активации, то больше с этим клиентом в этой активации связываться не нужно',
                    },
                    {
                        id: 'monthly',
                        name:
                            'Клиент каждый месяц 1 числа должен попадать в эту активацию для новой рассылки ему',
                    },
                    {
                        id: 'after_visit',
                        name:
                            'С клиентом по этой активации связались, он должен опять попасть в эту активацию только после очередного  посещения',
                    },
                ],
                valueField: 'regularity',
                defaultValue: 'one_time',
            },
        ],
    },
    consider_activation_priority: {
        defaultValue: true,
        canEdit: false,
        schema: [
            {
                type: 'text',
                text:
                    'Должны ли клиенты этой активации показываться по правилам приоритетов? Если на активацию работают приоритеты, в ней могут отображаться не все попадающие клиенты, пока она стоит не на первом месте. А клиенты, отобразившиеся в ней, исчезнут из других активаций. —',
            },
            {
                type: 'select',
                label: '',
                multiple: false,
                customList: [
                    {
                        id: true,
                        name: 'Да',
                    },
                    {
                        id: false,
                        name: 'Нет',
                    },
                ],
                valueField: 'consider_activation_priority',
                defaultValue: true,
            },
        ],
    },
    force_comm: {
        defaultValue: false,
        canEdit: true,
        schema: [
            {
                type: 'text',
                text:
                    'Должны ли клиенты из списка "Не напоминаем" попадать в новую активацию, если они подойдут под условия? —',
            },
            {
                type: 'select',
                label: '',
                multiple: false,
                customList: [
                    {
                        id: true,
                        name: 'Да',
                    },
                    {
                        id: false,
                        name: 'Нет',
                    },
                ],
                valueField: 'force_comm',
                defaultValue: false,
            },
        ],
    },
}

export const BOT_TRIGGER_CARDS = {
    afterRecord: {
        title: 'Новая запись',
        description:
            'Клиент записался к вам в салон🌝+🌚.\nСообщение сразу после записи для первичных🌝 и повторных🌚 клиентов.',
    },
    afterRecordNew: {
        title: 'Новая запись для клиентов, которые никогда не были в салоне',
        description:
            'Клиенты, которые никогда не были в салоне🌝.\nСообщение сразу после записи для тех, кто не имеет посещений.\nНе забудьте включить 🌚, чтобы клиенты, которые уже были у вас, тоже получали уведомление.',
    },
    afterRecordPrev: {
        title: 'Новая запись для клиентов, у которых был хотя бы 1 визит',
        description:
            'Клиенты, которые уже были у вас в салоне🌚.\nСообщение сразу после записи для тех, у кого было хотя бы одно посещение.\nНе забудьте включить 🌝, чтобы клиенты, которые не были у вас, тоже получали уведомление.',
    },
    notificBeforeRecord1Day: {
        title: 'Запрос подтверждения за сутки',
        description:
            'Сообщение с просьбой подтвердить запись за сутки до записи, но не раньше 10:00 и не позже 21:00.',
    },
    notificBeforeRecord2Hours: {
        title: 'Напоминание за 2 часа ',
        description:
            'Сообщение за два часа до записи. Если запись утренняя, то сообщение отправляется накануне вечером',
    },
    afterRecordCancelled: {
        title: 'Отмена записи',
        description: 'Сообщение в момент удаления записи или отмены записи',
    },
    afterRecordConfirmed: {
        title: 'Подтверждение записи',
        description: 'Сообщение в момент подтверждения записи',
    },
    afterRecordTimeChanged: {
        title: 'Перенос времени записи',
        description:
            'Сообщение при изменении времени записи. Сообщения не отправляются, если запись отменена или визит уже начался',
    },
    afterRecordDataChanged: {
        title: 'Изменения записи',
        description:
            'Сообщение при изменении мастера. Сообщения не отправляются, если запись отменена или визит уже начался',
    },
    afterVisit: {
        title: 'Запрос оценки визита',
        description:
            'Сообщение с просьбой оценить визит в момент завершения записи. При положительной оценке — предложение оставить отзыв, при отрицательной — сообщение управляющему',
    },
    afterRecordClientCome: {
        title: 'Клиент пришёл',
        description: 'Сообщение в момент, когда нажали кнопку «Клиент пришёл»',
    },
    RequestFeedback: {
        title: 'Запрос обработки рекламации',
        description: 'Сообщение с просьбой повторно оценить визит после обработки негатива.',
    },
}

<template>
    <div>
        <h1>Кого из кандидатов стоит позвать.</h1>
        <p>Отчет предсказывает, когда ваши клиенты должны к вам вернуться.</p>
        <p>Приглашайте ваших клиентов вернуться к вам, когда это больше всего им нужно.</p>
        <p>&nbsp;</p>
        <h2>Горячие клиенты</h2>
        <p>Это клиенты, которые с большой вероятностью придут к вам и без напоминания.</p>
        <p>
            Напоминание этим клиентам приведёт к наибольшим результатам, но вам или вашим сотрудникам придётся
            сделать в три раза больше работы по оповещению клиентов.
        </p>
        <p>&nbsp;</p>
        <h2>Забыли прийти</h2>
        <p>
            Списки клиентов, которые забыли к вам прийти. Обязательно напишите этим клиентам, иначе будет уже
            поздно.
        </p>
        <p>По статистике, около 10-20% клиентов из этого списка вернуться после напоминания.</p>
        <p>&nbsp;</p>
        <h2>Холодные клиенты</h2>
        <p>Все клиенты, которые были у вас за все время, но уже давно к вам не возвращались.</p>
        <p>
            Писать таким клиентам не эффективно. Но если у вас проходит акция или клиентский день, то можно
            написать и этим клиентам.
        </p>
        <p>&nbsp;</p>
        <h2>Статистика коммуникаций</h2>
        <p>
            На самой карточке отображается, сколько клиентов позвали за последние 4 недели (не включая
            сегодняшний день), и сколько из них пришли.
        </p>
        <p>В самом отчете можно:</p>
        <ul>
            <li>посмотреть данные за любой период;</li>
            <li>посмотреть данные в разрезе администраторов;</li>
            <li>увидеть полный список коммуникаций и их результатов.</li>
        </ul>
        <p>
            <strong>Важно!</strong> Посещение может быть за пределами выбранного периода. К примеру, если
            поставить фильтр с 1 января до 31 января, то будут показаны все коммуникации за этот период. Но
            клиенты могут прийти и за пределами этого периода, к примеру, 1 февраля. Такое посещение тоже
            будет учтено. Поэтому статистика приходов после коммуникации может меняться со временем.
        </p>
        <p>&nbsp;</p>
    </div>
</template>

<script>
//hintId  <dashBoards>
export default {
    name: 'GCBOutflowHint',
    computed: {},
    components: {},
    data: () => ({}),
    methods: {},
    mounted() {},
}
</script>

<style lang="sass" scoped>
@import '@/vars.sass'
p
    font-size: 14px
</style>
